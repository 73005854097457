import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Button from '../Button';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function PortfolioDetailsPage() {
  const params = useParams();
  pageTitle('Portfolio Details');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="InmboSigner"
        bgSrc="/images/inmbosigner-detail.png"
        pageLinkText={ params.portfolioDetailsId }
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <img
          src="/images/imbo-detail.png"
          alt="Details"
          className="cs-radius_15 w-100"
        />
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Proyecto de Manual de Marca InmboSigner por Sitenso"
              subtitle="Creative"
            >
              <Spacing lg="40" md="20" />
              <p>
                Nos complace compartir uno de los proyectos más significativos
                en el que trabajamos: la creación del Manual de Marca de
                'InmboSigner'. Este proyecto fue un emocionante viaje que se
                permitió fusionar nuestra por el diseño gráfico y amor por la
                gestión de proyectos.
              </p>
              <Spacing lg="10" md="10" />
              <h3>Descripción del Proyecto:</h3>
              <ul>
                <li>
                  El Cliente y su Desafío:
                  <p>
                    "InmboSigner" es una empresa en crecimiento en el campo de los
                    contratos inmobiliarios digitales. Se enfrentaban al reto de
                    establecer una identidad visual sólida y coherente que
                    reflejara su visión y atraiga a su audiencia.
                  </p>
                </li>
                <li>
                  Nuestro Rol y Responsabilidades:
                  <p>
                    En "Sitenso," asumimos la responsabilidad de transformar la
                    visión de "InmboSigner" en una identidad visual sólida. Esto
                    incluyó el diseño del logotipo, la selección de colores,
                    tipografía y pautas de uso.
                  </p>
                </li>
                <li>
                  El Proceso:
                  <p>
                    Nuestra metodología implicó investigar exhaustivamente la
                    industria y el público objetivo de "InmboSigner". Esto nos
                    permitió crear un Manual de Marca que captura la esencia de su
                    negocio y comunica su mensaje de manera efectiva.
                  </p>
                </li>
                <li>
                  Colaboración:
                  <p>
                    Trabajamos en estrecha colaboración con el equipo de
                    "InmboSigner" para garantizar que sus valores y objetivos se
                    reflejaran en la identidad visual de la marca.
                  </p>
                </li>
              </ul>
              <h3>Resultado:</h3>
              <p>
                Puedes explorar el resultado final de nuestro trabajo en el
                Manual de Marca de "InmboSigner" a través de su sitio [enlace].
                Observarás cómo cada elemento, desde el logotipo hasta la paleta
                de colores, contribuye a fortalecer la presencia de la marca.
              </p>
              <h3>Impacto:</h3>
              <p>
                El trabajo de "Sitenso" ha contribuido significativamente
                al éxito de "InmboSigner". La identidad visual creada ha
                fortalecido su presencia en el mercado, transmitiendo confianza y
                profesionalismo a sus clientes y socios.
              </p>
              <Spacing lg="60" md="40" />
              <h3>Agradecimientos:</h3>
              <h6>
                Queremos expresar nuestro
                agradecimiento a "InmboSigner" por la oportunidad de trabajar en
                este emocionante proyecto y por su confianza en nuestro equipo.
              </h6>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">
              Info del proyecto:
            </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Categoria:
                </h3>
                <p className="cs-m0">Diseño</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Ubicación:
                </h3>
                <p className="cs-m0">Buenos Aires</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Software:
                </h3>
                <p className="cs-m0">Adobe Illustrator - Figma</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Cliente:
                </h3>
                <p className="cs-m0">Inmbosigner</p>
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg="65" md="10" />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button
              btnLink="/portfolio/myb"
              btnText="Anterior Proyecto"
              variant="cs-type1"
            />
          </Div>
          <Div>
            <Button
              btnLink="/portfolio/tensolite"
              btnText="Próximo Proyecto"
            />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
    </>
  );
}
