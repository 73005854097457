import React from 'react';
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({ withIcon, title }) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li className="contact-page">
          {withIcon ? <span className="cs-accent_color"><Icon icon="material-symbols:add-call-rounded" /></span> : ''}
          +54 9 3815112067
        </li>
        <li className="contact-page">
          {withIcon ? <span className="cs-accent_color"><Icon icon="mdi:envelope" /></span> : ''}
          ventas.sitenso@tensolite.com
        </li>
        <li className="contact-page">
          {withIcon ? <span className="cs-accent_color"><Icon icon="mdi:map-marker" /></span> : ''}
          Ruta Nac., 9 1298
          {' '}
          <br />
          {' '}
          Tafí Viejo, Tucumán
        </li>
      </ul>
    </>
  );
}
