import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link
        to="https://www.linkedin.com/company/sitenso/mycompany/"
        rel="noreferrer"
        target="_blank"
        className="cs-center"
      >
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
      <Link
        to="https://www.tiktok.com/@sitenso_ok"
        rel="noreferrer"
        target="_blank"
        className="cs-center"
      >
        <Icon icon="ic:baseline-tiktok" />
      </Link>
      <Link
        to="https://www.facebook.com/sitenso"
        rel="noreferrer"
        target="_blank"
        className="cs-center"
      >
        <Icon icon="ic:baseline-facebook" />
      </Link>
      <Link
        to="https://www.instagram.com/sitenso_ok/"
        rel="noreferrer"
        target="_blank"
        className="cs-center"
      >
        <Icon icon="mdi:instagram" />
      </Link>
    </Div>
  );
}
