import React from 'react';
import Div from '../Div';
import './logolist.scss';

export default function LogoList() {
  const partnerLogos = [
    {
      src: '/images/Tensolite.svg',
      alt: 'Tensolite',
    },
    {
      src: '/images/conCodigo-isotipo.svg',
      alt: 'ConCodigo',
    },
    {
      src: '/images/marmoleria-isotipo.svg',
      alt: 'MYB',
    },
    {
      src: '/images/inmbosigner-isotipo.svg',
      alt: 'Inmbosigner',
    },
  ];
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index) => (
        <div className="cs-partner_logo" key={ index }>
          <img src={ partnerLogo.src } alt={ partnerLogo.alt } />
        </div>
      ))}
    </Div>
  );
}
