import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Button from '../Button';
import Cta from '../Cta';
import IconBox from '../IconBox';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        bgSrc="/images/mkt-portada.jpg"
        pageLinkText={ params.serviceDetailsId }
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Nuestro Proceso de Trabajo"
          subtitle="Marketing Digital"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_1.png"
              title="Investigación de Mercado"
              subtitle="Exploramos a fondo tu nicho para identificar oportunidades y desafíos. Entendemos a tus clientes y competidores para crear una estrategia efectiva."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.png"
              title="Diseño de Estrategia"
              subtitle="Creamos estrategias de marketing personalizadas que se alinean con tus objetivos comerciales. Cada decisión se basa en datos y en tu visión."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.png"
              title="Implementación Creativa"
              subtitle="Llevamos a cabo campañas creativas que capturan la atención y atraen a tu audiencia. Creamos contenido atractivo y publicidad impactante."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.png"
              title="Seguimiento y Análisis"
              subtitle="Medimos el éxito en tiempo real, ajustando estrategias según los datos. Optimizamos continuamente para lograr los mejores resultados."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.png"
              title="Crecimiento Sostenible"
              subtitle="Nuestra misión es tu éxito a largo plazo. Trabajamos incansablemente para hacer crecer tu presencia y tu impacto en el mercado."
            />
            <Spacing lg="30" md="30" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/marketing.png"
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_40 cs-m0">
              Somos más que una agencia de marketing, somos tu socio estratégico
            </h2>
            <Spacing lg="100" md="30" />
            <Div className="row">
              <Div className="col-lg-6">
                <Button
                  btnLink="/contact"
                  btnText="Creación de Contenido de Valor"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Crecimiento en Redes Sociales"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Visibilidad de Marca"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Innovación y Creatividad"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Generación de Leads"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Aumento de Ventas"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
              </Div>
              <Div className="col-lg-6">
                <Button
                  btnLink="/contact"
                  btnText="Fidelización de Clientes"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Crecimiento Sostenible"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Segmentación de Audiencia"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Medición y Analítica"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Internacionalización"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />

              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4" />
        <Div className="cs-shape_4" />
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="¿Alguna duda o pregunta?"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Impulsá tu éxito digital con nosotros: donde la innovación cobra vida y el diseño vende."
          btnText="Contactanos!"
          btnLink="/contact"
          bgSrc="/images/contacto-baner-home.png"
        />
      </Div>
      <Spacing lg="150" md="80" />
    </>
  );
}
