import React, { useState } from 'react';

import Div from '../Div';

export default function Accordion() {
  const [selected, setSelected] = useState(null);
  const handelToggle = (index) => {
    if (selected === index) {
      return setSelected(null);
    }

    setSelected(index);
  };
  const accordionData = [
    {
      id: 0,
      question: '¿Qué servicios de Marketing ofrecen?',
      answer: 'Podemos brindarte un servicio integral o personalizado en diferentes áreas, tales como plan de marketing, visualización estratégica, gestión de RRSS, diseño de marca gráfica y más.',
    },
    {
      id: 1,
      question: '¿Qué tipo de desarrollos ofrecen?',
      answer: 'Desarrollamos tu sitio web a medida, adaptada a tus necesidades y objetivos. Contamos con un equipo de expertos en diseño, programación y marketing, que garantizan un resultado de calidad funcional y atractivo.',
    },
    {
      id: 2,
      question: '¿Qué tipos de servicios gráficos brindan?',
      answer: 'Este servicio, totalmente personalizado, le brinda a nuestro socio una identidad visual atractiva y coherente en base a los objetivos. Identificación que transmitirá sus valores y personalidad. Tener una identidad visual definida te permitirá transmitir una imagen profesional y confiable a tus clientes.',
    },
    {
      id: 3,
      question: '¿Cuáles son los medios de pago?',
      answer: 'Con respecto a nuestros medios de pago, trabajamos con: Depósitos, transferencias bancarias, cheques o e-cheq.',
    },
    {
      id: 4,
      question: '¿Cuáles son sus horarios de atención?',
      answer: 'Nuestros horarios comerciales son: Lunes a viernes de 8:00 a 17:00hs. (GTM +3).',
    },
  ];
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index) => (
        <Div className={ `cs-accordian ${selected === index ? 'active' : ''}` } key={ item?.id }>
          <Div className="cs-accordian_head" onClick={ () => handelToggle(index) }>
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg
                width={ 15 }
                height={ 8 }
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>
            </span>
          </Div>
          <Div className="cs-accordian_body">
            <Div className="cs-accordian_body_in">{item.answer}</Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
}
