import React from 'react';
import Div from '../Div';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
import TextWidget from '../Widget/TextWidget';
import './footer.scss';

export default function Footer() {
  const serviceMenu = [
    {
      title: 'Desarrollo Web',
      href: '/service/desarrollo',
    },
    {
      title: 'Marketing Digital',
      href: '/service/marketing',
    },
    {
      title: 'Diseño',
      href: '/service/diseno-grafico',
    },
  ];

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-4 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/logo-sitenso-blanco.svg"
                  logoAlt="Logo"
                  text="Innovation for you"
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-4 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={ serviceMenu } menuHeading="Servicios" />
              </Div>
            </Div>
            <Div className="col-lg-4 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contacto" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
