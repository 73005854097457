import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Button from '../Button';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function PortfolioDetailsPage() {
  const params = useParams();
  pageTitle('Portfolio Details');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Marmoleria Yerba Buena"
        bgSrc="/images/myb-detail.png"
        pageLinkText={ params.portfolioDetailsId }
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <img
          src="/images/R-ClienteList.gif"
          alt="Details"
          className="cs-radius_15 w-100"
        />
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Proyecto Web App de MYB por Sitenso"
              subtitle="Creative"
            >
              <Spacing lg="40" md="20" />
              <p>
                {'Tenemos el placer de compartir uno de nuestros proyectos más destacados: la creación de la web app para "Marmolería Yerba Buena". Este proyecto representa un emocionante viaje donde unimos nuestra pasión por el diseño web y nuestra habilidad en la gestión de proyectos.'}
              </p>
              <Spacing lg="10" md="10" />
              <h3>Descripción del Proyecto:</h3>
              <ul>
                <li>
                  El Cliente y su Desafío:
                  <p>
                    {'"Marmolería Yerba Buena" es una empresa de gran tradición en el sector de la marmolería. Se enfrentaban al desafío de modernizar su presencia en línea y ofrecer a sus clientes una experiencia de usuario mejorada.'}
                  </p>
                </li>
                <li>
                  Nuestro Rol y Responsabilidades:
                  <p>
                    {'En "Sitenso," asumimos la responsabilidad de diseñar y desarrollar una web app personalizada para "Marmolería Yerba Buena". Esto incluyó la creación de una interfaz de usuario intuitiva y la optimización para dispositivos móviles.'}
                  </p>
                </li>
                <li>
                  El Proceso:
                  <p>
                    {' Nuestro enfoque implicó investigar exhaustivamente la industria y las necesidades de los clientes de "Marmolería Yerba Buena". Esto nos permitió crear una web app que simplifica la comunicación con los clientes y mejora la eficiencia operativa.'}
                  </p>
                </li>
                <li>
                  Colaboración:
                  <p>
                    {'Trabajamos en estrecha colaboración con el equipo de "Marmolería Yerba Buena" para asegurarnos de que la web app reflejara fielmente su experiencia y servicios de alta calidad.'}
                  </p>
                </li>
              </ul>
              <h3>Resultado:</h3>
              <p>
                {'Puedes explorar el resultado final de nuestro trabajo en la web app de "Marmolería Yerba Buena" a través de este [enlace]. Observarás cómo cada detalle, desde la navegación hasta la disposición de la información, contribuye a una experiencia en línea fluida y efectiva.'}
              </p>
              <h3>Impacto:</h3>
              <p>{'La web app diseñada y desarrollada por "Sitenso" ha tenido un impacto significativo en "Marmolería Yerba Buena". La empresa ahora puede interactuar de manera más eficiente con sus clientes, lo que ha llevado a un aumento en la satisfacción del cliente y un mejor posicionamiento en línea.'}</p>
              <Spacing lg="30" md="40" />
              <video src="/images/myb.mp4" controls className="video-myb" />
              <Spacing lg="30" md="40" />
              <h3>Agradecimientos:</h3>
              <h6>{'Expresamos nuestro agradecimiento a "Marmolería Yerba Buena" por confiar en nosotros para llevar a cabo este emocionante proyecto y por su colaboración en su realización.'}</h6>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">
              Info del proyecto:
            </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Categoria:
                </h3>
                <p className="cs-m0">Desarrollo</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Ubicación:
                </h3>
                <p className="cs-m0">Tucumán</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Software:
                </h3>
                <p className="cs-m0">React js - Laravel</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Cliente:
                </h3>
                <p className="cs-m0">Marmolería Yerba Buena</p>
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg="65" md="10" />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button
              btnLink="/portfolio/concodigo"
              btnText="Anterior Proyecto"
              variant="cs-type1"
            />
          </Div>
          <Div>
            <Button
              btnLink="/portfolio/inmbosigner"
              btnText="Próximo Proyecto"
            />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
    </>
  );
}
