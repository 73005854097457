import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function AboutPage() {
  pageTitle('About');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        bgSrc="images/About-4.jpg"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Nuestra pasión por el marketing se traduce en resultados para nuestros clientes"
              subtitle="Sobre nosotros"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                {'Somos una agencia dispuesta a ser tu aliado estratégico en la innovación y cumplimiento de tus objetivos. En Sitenso contamos con un grupo de expertos especializados en cada servicio, cuya misión es simple: impulsar tu presencia en línea, brindarte las herramientas apropiadas para fortalecer la identidad tu marca y así conseguir los objetivos pautados en la planificación. ¡Trabajemos juntos y logremos el éxito que tu negocio merece!'}
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg" />
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/Ab-2.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/Ab-1.jpg"
              alt="About"
              className="w-80 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/dev-detail.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="100" md="80" />

      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/Ab-3.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Nuestra misión es simple: convertir tus desafíos en oportunidades"
              subtitle="¿Por qué elegirnos?"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                {'Oportunidad para superar expectativas, detrás de cada campaña exitosa hay un equipo dedicado. Conocé a los talentosos profesionales que hacen posible nuestro éxito y el tuyo.'}
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                En un mundo digital en constante evolución, nuestro equipo de expertos en marketing está a la vanguardia de las últimas tendencias y tecnologías.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg" />
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="¿Tenés un proyecto en mente?"
          btnText="Contactanos"
          btnLink="/contact"
          bgSrc="/images/contacto-baner-home.png"
        />
      </Div>
      <Spacing lg="150" md="80" />
    </>
  );
}
