import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Button from '../Button';
import Cta from '../Cta';
import IconBox from '../IconBox';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        bgSrc="/images/dg-portada.png"
        pageLinkText={ params.serviceDetailsId }
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Nuestro Proceso de Trabajo"
          subtitle="Diseño Gráfico"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_1.png"
              title="Investigación y Análisis"
              subtitle="Exploramos en profundidad tus objetivos y necesidades para comprender la esencia de tu proyecto. Analizamos tu audiencia y competencia para crear una estrategia efectiva."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.png"
              title="Conceptualización"
              subtitle="Generamos ideas creativas y conceptos innovadores que sirven como base para el diseño. Aquí es donde nace la visión que dará forma a tu proyecto."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.png"
              title="Diseño Gráfico"
              subtitle="Transformamos ideas en diseños visuales atractivos y funcionales. Creamos identidades de marca, ilustraciones y elementos visuales que cautivan a tu audiencia."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.png"
              title="Diseño de Interfaz (UI)"
              subtitle="Diseñamos interfaces intuitivas y atractivas que hacen que tu producto o sitio web sea fácil de usar. Cada detalle se cuida para brindar la mejor experiencia al usuario."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.png"
              title="Prototipado"
              subtitle="Creamos prototipos interactivos que te permiten visualizar y probar cómo funcionará tu proyecto. Esto asegura que estés completamente satisfecho antes de avanzar."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_1.png"
              title="Iteración y Optimización"
              subtitle="A través de pruebas y retroalimentación, refinamos y optimizamos continuamente los diseños para lograr resultados excepcionales."
            />
            <Spacing lg="30" md="30" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/dg-detail.png"
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_40 cs-m0">
              Descubre cómo nuestro enfoque puede impulsar tu proyecto
            </h2>
            <Spacing lg="100" md="30" />
            <Div className="row">
              <Div className="col-lg-6">
                <Button
                  btnLink="/contact"
                  btnText="Identidad Visual Impactante"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Diseño de Interfaces Intuitivas"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Diseño Centrado en el Usuario"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Contenido Visual Impactante"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Seguimiento y Analítica de Diseño"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
              </Div>
              <Div className="col-lg-6">
                <Button
                  btnLink="/contact"
                  btnText="Personalización de Diseño"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Diseño Innovador y Creativo"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Destacamos entre la Competencia"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Ampliación Internacional"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4" />
        <Div className="cs-shape_4" />
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="¿Alguna duda o pregunta?"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Impulsá tu éxito digital con nosotros: donde la innovación cobra vida y el diseño vende."
          btnText="Contactanos!"
          btnLink="/contact"
          bgSrc="/images/contacto-baner-home.png"
        />
      </Div>
      <Spacing lg="150" md="80" />
    </>
  );
}
