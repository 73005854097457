import React, { useEffect } from 'react';
import Card from '../Card';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import VideoModal from '../VideoModal';
import { pageTitle } from '../../helper';

export default function Home() {
  pageTitle('Home');

  const heroSocialLinks = [
    {
      name: 'Linkedin',
      links: 'https://ar.linkedin.com/company/sitenso',
    },
    {
      name: 'Instagram',
      links: 'https://www.instagram.com/sitenso_ok/?hl=es',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero
        title="Innovation for you!"
        subtitle="Transformamos ideas en éxito digital mediante una fusión única de desarrollo innovador, estrategias de marketing vanguardistas y diseño cautivador"
        btnText="Contactanos!"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Seguinos"
        heroSocialLinks={ heroSocialLinks }
        bgImageUrl="/images/background-home.png"
      />
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Nuestros servicios"
                subtitle="¿En qué podemos ayudarte?"
                btnText=" "
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile" />
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Diseño"
                    link="/service/diseno-grafico"
                    src="/images/Branding.png"
                    alt="branding"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile" />
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Desarrollo web"
                    link="/service/desarrollo"
                    src="/images/developer.png"
                    alt="developer"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Marketing"
                    link="/service/marketing"
                    src="/images/marketing.png"
                    alt="marketing"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile" />
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Publicidad Online"
                    link="/service/diseno-grafico"
                    src="/images/uxui.png"
                    alt="ui ux design"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Mirá nuestros trabajos"
            subtitle="¿Querés conocer más?"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div>

      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          "En Sitenso, fusionamos creatividad y tecnología. Un día en nuestro mundo de desarrollo e innovación..."
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=58O3DGJQCGc"
          bgUrl="/images/video-portada.png"
        />
      </Div>

      <Spacing lg="145" md="80" />
      <Spacing lg="150" md="80" />

      <TestimonialSlider />

      <Spacing lg="150" md="80" />

      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />

      <MovingText text="Construyendo Éxito Digital, Diseño a Cada Código." />
      <Spacing lg="105" md="70" />

      <Div className="container">
        <Cta
          title="Impulsá tu éxito digital con nosotros: donde la innovación cobra vida y el diseño vende."
          btnText="Contactanos!"
          btnLink="/contact"
          bgSrc="/images/contacto-baner-home.png"
        />
        <Spacing lg="105" md="70" />
      </Div>
    </>
  );
}
