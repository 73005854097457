export const sendEmail = async (form) => {
  const { REACT_APP_DEVMAIL, REACT_APP_ENV, REACT_APP_RECIPIENT1, REACT_APP_DEVMAILP, REACT_APP_RECIPIENT2, REACT_APP_RECIPIENT3, REACT_APP_URL } = process.env;

  const emails = REACT_APP_ENV ? [REACT_APP_DEVMAIL, REACT_APP_DEVMAILP]
    : [REACT_APP_RECIPIENT1, REACT_APP_RECIPIENT2, REACT_APP_RECIPIENT3];

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...form, emails,
    }),
  };
  const response = await fetch(`${REACT_APP_URL}`, params);

  if (!response.ok) {
    throw new Error('Error al enviar el correo electrónico');
  }

  return response;
};
