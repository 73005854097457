import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Button from '../Button';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function PortfolioDetailsPage() {
  const params = useParams();
  pageTitle('Portfolio Details');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Con Código"
        bgSrc="/images/ConCodigoDetail.png"
        pageLinkText={ params.portfolioDetailsId }
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <img
          src="/images/Base-CC.png"
          alt="Details"
          className="cs-radius_15 w-100"
        />
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Proyecto Web para Con Código por Sitenso"
              subtitle="Creative"
            >
              <Spacing lg="40" md="20" />
              <p>
                {'Nos complace presentar uno de nuestros proyectos más recientes y destacados: la creación de la página web para "Con Código". Este proyecto representa un emocionante viaje en el que hemos aplicado nuestra experiencia en diseño web y gestión de proyectos para ayudar a "Con Código" a modernizar su presencia en línea y proporcionar a sus clientes una plataforma eficiente para explorar sus productos y servicios en el sector de materiales de construcción.'}
              </p>
              <Spacing lg="10" md="10" />
              <h3>Descripción del Proyecto:</h3>
              <ul>
                <li>
                  El Cliente y su Desafío:
                  <p>
                    {'"Con Código" es una destacada distribuidora de materiales de construcción que se enfrentaba al reto de actualizar su presencia en línea para ofrecer a sus clientes una experiencia más completa y efectiva en la búsqueda y adquisición de materiales de construcción.'}
                  </p>
                </li>
                <li>
                  Nuestro Rol y Responsabilidades:
                  <p>
                    {'En "Sitenso", asumimos la responsabilidad de diseñar y desarrollar un sitio web personalizado para "Con Código". Nuestra tarea incluyó la creación de una interfaz de usuario intuitiva y la optimización para dispositivos móviles, con el objetivo de mejorar la experiencia del usuario.'}
                  </p>
                </li>
                <li>
                  El Proceso:
                  <p>
                    {'Nuestra metodología incluyó una investigación exhaustiva en la industria de materiales de construcción y las necesidades específicas de los clientes de "Con Código". Esto nos permitió crear un sitio web que simplifica la búsqueda y adquisición de materiales, mejorando la presentación de productos y servicios.'}
                  </p>
                </li>
                <li>
                  Colaboración:
                  <p>
                    {'Trabajamos estrechamente con el equipo de "Con Código" para asegurarnos de que el sitio web reflejara con precisión su experiencia y calidad en la distribución de materiales de construcción.'}
                  </p>
                </li>
              </ul>
              <h3>Resultado:</h3>
              <p>
                {'Puedes explorar el resultado final de nuestro trabajo en el sitio web de "Con Código" a través de este '}
                <a href="https://tensolite.com/" target="_blank" rel="noreferrer">{'Con Código'}</a>
                {'. Observarás cómo cada detalle, desde la navegación hasta la presentación de productos, contribuye a una experiencia en línea eficiente y efectiva para los clientes que buscan materiales de construcción.'}
              </p>
              <h3>Impacto:</h3>
              <p>{'La web diseñada y desarrollada por "Sitenso" ha tenido un impacto significativo en "Con Código". La empresa ahora puede interactuar de manera más efectiva con sus clientes, lo que ha llevado a un aumento en la visibilidad de sus productos y una mayor satisfacción del cliente en la búsqueda y adquisición de materiales de construcción.'}</p>
              <Spacing lg="30" md="40" />
              <img src="/images/mockConCodigo.png" alt="" />
              <Spacing lg="30" md="40" />
              <iframe
                className="iframe-video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Sug7yiLmgoI?si=LZ9OrTatAZ3E3t5j"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
              <Spacing lg="30" md="40" />
              <h3>Agradecimientos:</h3>
              <h6>{'Estamos encantados de haber colaborado con "Con Código" en este emocionante proyecto y esperamos seguir apoyándolos en su éxito continuo en la industria de materiales de construcción.'}</h6>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">
              Info del proyecto:
            </h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Categoria:
                </h3>
                <p className="cs-m0">Desarrollo</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Ubicación:
                </h3>
                <p className="cs-m0">Tucumán</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Software:
                </h3>
                <p className="cs-m0">React js - Laravel</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Cliente:
                </h3>
                <p className="cs-m0">Con Código</p>
                <Spacing lg="30" md="30" />
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg="65" md="10" />
        <Div className="cs-page_navigation cs-center">
          <Div>
            <Button
              btnLink="/portfolio/tensolite"
              btnText="Anterior Proyecto"
              variant="cs-type1"
            />
          </Div>
          <Div>
            <Button
              btnLink="/portfolio/myb"
              btnText="Próximo Proyecto"
            />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
    </>
  );
}
