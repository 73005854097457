import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import Inmbosigner from './components/Pages/inmbosigner';
import Myb from './components/Pages/Myb';
import Tenso from './components/Pages/Tensolite';
import ConCodigo from './components/Pages/ConCodigo';
import ServicesPage from './components/Pages/ServicesPage';
import TeamPage from './components/Pages/TeamPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import TeamDetails from './components/Pages/TeamDetails';
import PhotographyAgencyHome from './components/Pages/PhotographyAgencyHome';
import CreativePortfolioHome from './components/Pages/CreativePortfolioHome';
import DigitalAgencyHome from './components/Pages/DigitalAgencyHome';
import MarketingAgencyHome from './components/Pages/MarketingAgencyHome';
import ShowcasePortfolioHome from './components/Pages/ShowcasePortfolioHome';
import CaseStudyShowcaseHome from './components/Pages/CaseStudyShowcaseHome';
import Layout from './components/Layout';
import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
import FaqPage from './components/Pages/FaqPage';
import MarketingDetail from './components/Pages/marketingDetail';
import DevDetail from './components/Pages/devDetail';
import PoliticaPrivacidad from './components/Pages/PoliticaPrivacidad';
import DgDetaill from './components/Pages/disenoGraficoDetail';

function App() {
  return (
    <Routes>
      <Route path="/" element={ <Layout /> }>
        <Route index element={ <Home /> } />
        <Route
          path="photography-agency"
          element={ <PhotographyAgencyHome /> }
        />
        <Route path="digital-agency" element={ <DigitalAgencyHome /> } />
        <Route path="marketing-agency" element={ <MarketingAgencyHome /> } />
        <Route path="about" element={ <AboutPage /> } />
        <Route path="service" element={ <ServicesPage /> } />
        <Route path="service/marketing" element={ <MarketingDetail /> } />
        <Route path="service/desarrollo" element={ <DevDetail /> } />
        <Route path="service/diseno-grafico" element={ <DgDetaill /> } />
        <Route path="portfolio" element={ <PortfolioPage /> } />
        <Route
          path="portfolio/:portfolioDetailsId"
          element={ <PortfolioDetailsPage /> }
        />
        <Route path="portfolio/inmbosigner" element={ <Inmbosigner /> } />
        <Route path="portfolio/myb" element={ <Myb /> } />
        <Route path="portfolio/tensolite" element={ <Tenso /> } />
        <Route path="portfolio/concodigo" element={ <ConCodigo /> } />
        <Route path="blog" element={ <BlogPage /> } />
        <Route path="blog/:blogDetailsId" element={ <BlogDetailsPage /> } />
        <Route path="contact" element={ <ContactPage /> } />
        <Route path="team" element={ <TeamPage /> } />
        <Route path="team/:teamDetails" element={ <TeamDetails /> } />
        <Route
          path="/case-study/:caseStudyDetailsId"
          element={ <CaseStudyDetailsPage /> }
        />
        <Route path="faq" element={ <FaqPage /> } />
      </Route>
      <Route
        path="/"
        element={ <Layout headerVariant="cs-site_header_full_width" /> }
      >
        <Route
          path="creative-portfolio"
          element={ <CreativePortfolioHome /> }
        />
        <Route
          path="showcase-portfolio"
          element={ <ShowcasePortfolioHome /> }
        />
        <Route
          path="case-study-showcase"
          element={ <CaseStudyShowcaseHome /> }
        />
      </Route>
      <Route
        path="/politica-de-privacidad"
        element={ <PoliticaPrivacidad /> }
      />
      <Route path="*" element={ <ErrorPage /> } />
    </Routes>
  );
}

export default App;
