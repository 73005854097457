import React from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Portfolio from '../Portfolio';
import Div from '../Div';

export default function PortfolioSlider2() {
  const portfolioData = [
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_31.jpeg',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_32.jpeg',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_33.jpeg',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_34.jpeg',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_31.jpeg',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_32.jpeg',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_33.jpeg',
    },
    {
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_34.jpeg',
    },
  ];

  /** Slider Settings * */
  function SlickArrowLeft({ currentSlide, ...props }) {
    return (
      <div
        { ...props }
        className={
          `slick-prev slick-arrow${currentSlide === 0 ? ' slick-disabled' : ''}`
        }
        aria-hidden="true"
        aria-disabled={ currentSlide === 0 }
      >
        <Icon icon="bi:arrow-left" />
      </div>
    );
  }
  function SlickArrowRight({ currentSlide, slideCount, ...props }) {
    return (
      <div
        { ...props }
        className={
          `slick-next slick-arrow${
            currentSlide === slideCount - 1 ? ' slick-disabled' : ''}`
        }
        aria-hidden="true"
        aria-disabled={ currentSlide === slideCount - 1 }
      >
        <Icon icon="bi:arrow-right" />
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider { ...settings } className="cs-gap-12 cs-arrow_style4">
      {portfolioData.map((item, index) => (
        <Div key={ index }>
          <Portfolio
            title={ item.title }
            subtitle={ item.subtitle }
            href={ item.href }
            src={ item.src }
            variant="cs-style1 cs-type2 cs-size3"
          />
        </Div>
      ))}
    </Slider>
  );
}
