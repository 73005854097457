import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';

import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function PortfolioPage() {
  pageTitle('Portfolio');
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(7);
  const portfolioData = [
    {
      title: 'Tensolite',
      subtitle: 'Ver más!',
      href: '/portfolio/tensolite',
      src: '/images/Tensolite.png',
      category: 'web_design',
    },
    {
      title: 'Con Codigo',
      subtitle: 'Ver más!',
      href: '/portfolio/concodigo',
      src: '/images/conCodigo.svg',
      category: 'web_design',
    },
    {
      title: 'MYB Yerba Buena',
      subtitle: 'Ver más!',
      href: '/portfolio/myb',
      src: '/images/MyB-yerbaBuena.svg',
      category: 'mobile_apps',
    },
    {
      title: 'Inmbosigner',
      subtitle: 'Ver más!',
      href: '/portfolio/inmbosigner',
      src: '/images/inmbosigner.png',
      category: 'dg',
    },
  ];
  const categoryMenu = [
    {
      title: 'Web Design',
      category: 'web_design',
    },
    {
      title: 'UI/UX Design',
      category: 'ui_ux_design',
    },
    {
      title: 'Mobile Apps',
      category: 'mobile_apps',
    },
    {
      title: 'Diseño Grafico',
      category: 'dg',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Portfolio"
        bgSrc="images/portfolio.jpg"
        pageLinkText=""
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Trabajos más recientes!" subtitle="Mira nuestros" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={ active === 'all' ? 'active' : '' }>
                <span onClick={ () => setActive('all') }>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={ active === item.category ? 'active' : '' }
                  key={ index }
                >
                  <span onClick={ () => setActive(item.category) }>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={ `${
                index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
              } ${
                active === 'all'
                  ? ''
                  : !(active === item.category)
                    ? 'd-none'
                    : ''
              }` }
              key={ index }
            >
              <Portfolio
                title={ item.title }
                subtitle={ item.subtitle }
                href={ item.href }
                src={ item.src }
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={ () => setItemShow(itemShow + 3) }
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="Te presentamos nuestras historias de éxito: Descubre cómo hemos sido parte del crecimiento de estas marcas."
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
