import React from 'react';
import Div from '../Div';

export default function PageHeading({ title, bgSrc, pageLinkText }) {
  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg"
      style={{
        backgroundImage: `url(${bgSrc})`,
      }}
    >
      <Div className="container">
        <Div className="cs-page_heading_in">
          <h1 className="cs-page_title cs-font_50 cs-hero_title">{title}</h1>
          <ol className="breadcrumb text-uppercase">
            <li className="breadcrumb-item cs-hero_subtitle">{pageLinkText}</li>
          </ol>
        </Div>
      </Div>
    </Div>
  );
}
