import React from 'react';

import PricingTable from '.';
import Section from '../Div';
import Spacing from '../Spacing';

export default function PricingTableList() {
  return (
    <Section className="position-relative">
      <Section className="row">
        <Section className="col-lg-4">
          <PricingTable
            title="STARTER"
            features={ ['Estrategia de contenido', 'Optimización de biografía', 'Estrategia de hashtags', '6 Publicaciones en feed', '1 Carruseles', 'Redacción de copies', 'Material Visual', 'Reporte de métricas'] }
            btnText="Contáctanos"
            btnLink="/contact"
          />
          <Spacing lg="25" md="25" />
        </Section>
        <Section className="col-lg-4">
          <PricingTable
            title="PREMIUM"
            features={ ['Estrategia de contenido', 'Optimización de biografía', 'Historias destacadas (Hasta 5)', 'Estrategia de hashtags', '20 Stories', '9 Publicaciones en feed', '2 Carruseles', '4 Reels', 'Redacción de copies', 'Material Visual', 'Reporte de métricas'] }
            btnText="Contáctanos"
            btnLink="/contact"
          />
          <Spacing lg="25" md="25" />
        </Section>
        <Section className="col-lg-4">
          <PricingTable
            title="ELITE"
            features={ ['Estrategia de contenido', 'Optimización de biografía', 'Historias destacadas (Hasta 8)', 'Estrategia de hashtags', '40 Stories', '9 Publicaciones en feed', '4 Carruseles', '4 Reels', 'Redacción de copies', 'Material Visual', 'Reporte de métricas'] }
            btnText="Contáctanos"
            btnLink="/contact"
          />
          <Spacing lg="25" md="25" />
        </Section>
      </Section>
    </Section>
  );
}
