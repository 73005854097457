import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Button from '../Button';
import Cta from '../Cta';
import IconBox from '../IconBox';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        bgSrc="/images/portada-dev.jpg"
        pageLinkText={ params.serviceDetailsId }
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Nuestro Proceso de Trabajo"
          subtitle="Desarrollo Web"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_1.png"
              title="Investigación y Análisis"
              subtitle="Exploramos a fondo tus necesidades y objetivos para definir una estrategia de desarrollo efectiva. Comprendemos tus requerimientos y la competencia para crear soluciones personalizadas."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.png"
              title="Diseño y Planificación"
              subtitle="Creamos una hoja de ruta detallada para tu proyecto web, asegurando que cada paso se alinee con tus metas. Nuestra planificación se basa en las mejores prácticas y la experiencia acumulada."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.png"
              title="Desarrollo Creativo"
              subtitle="Llevamos a cabo la creación y construcción de tu sitio o aplicación web con enfoque en la usabilidad y el diseño atractivo. Creamos soluciones que cautivan a tus usuarios y cumplen con tus expectativas."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_2.png"
              title="Pruebas y Optimización"
              subtitle="Realizamos pruebas exhaustivas para garantizar el rendimiento y la calidad. Optimizamos continuamente para asegurar que tu proyecto funcione a la perfección."
            />
            <Spacing lg="30" md="30" />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon="/images/icons/service_icon_3.png"
              title="Crecimiento Sostenible"
              subtitle="Nuestra misión es tu éxito continuo. Trabajamos para mejorar y expandir tus proyectos web a medida que evolucionan tus necesidades."
            />
            <Spacing lg="30" md="30" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="120" md="50" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/dev-detail.png"
                alt="Service"
                className="cs-radius_15 w-100"
              />
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_40 cs-m0">
              Expertos en desarrollo web y web apps. Tu presencia en línea,
              nuestra pasión.
            </h2>
            <Spacing lg="100" md="30" />
            <Div className="row">
              <Div className="col-lg-6">
                <Button
                  btnLink="/contact"
                  btnText="Diseño Responsivo"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Velocidad de Carga"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Seguridad"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Usabilidad"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="SEO"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Accesibilidad"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Desarrollo Front-end y Back-end"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Integración de APIs"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
              </Div>
              <Div className="col-lg-6">
                <Button
                  btnLink="/contact"
                  btnText="Buenas Prácticas"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Gestión de Contenido"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Desarrollo de Aplicaciones Web"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="E-commerce"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Mantenimiento y Soporte"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Adaptabilidad Tecnológica"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Proyectos Personalizados"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
                <Button
                  btnLink="/contact"
                  btnText="Entrega a Tiempo"
                  variant="cs-type2"
                />
                <Spacing lg="20" md="10" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4" />
        <Div className="cs-shape_4" />
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title="¿Alguna duda o pregunta?"
                subtitle="FAQ’s"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Impulsá tu éxito digital con nosotros: donde la innovación cobra vida y el diseño vende."
          btnText="Contactanos!"
          btnLink="/contact"
          bgSrc="/images/contacto-baner-home.png"
        />
      </Div>
      <Spacing lg="150" md="80" />
    </>
  );
}
