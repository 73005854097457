import React from 'react';
import Slider from 'react-slick';
import Portfolio from '../Portfolio';
import Div from '../Div';

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title: 'Marketing',
      subtitle: 'Conocé más',
      href: '/service/marketing',
      src: '/images/mkt-portfolio.png',
    },
    {
      title: 'Desarrollo',
      subtitle: 'Conocé más',
      href: '/service/desarrollo',
      src: '/images/dev.png',
    },
    {
      title: 'Diseño',
      subtitle: 'Conocé más',
      href: '/service/diseno-grafico',
      src: '/images/design.png',
    },
    {
      title: 'Branding',
      subtitle: 'Conocé más',
      href: '/service/diseno-grafico',
      src: '/images/brand.png',
    },
  ];

  /** Slider Settings * */
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider { ...settings } className="cs-slider cs-style3 cs-gap-24">
      {portfolioData.map((item, index) => (
        <Div key={ index }>
          <Portfolio
            title={ item.title }
            subtitle={ item.subtitle }
            href={ item.href }
            src={ item.src }
          />
        </Div>
      ))}
    </Slider>
  );
}
