import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import SectionHeading from '../SectionHeading';

export default function ErrorPage() {
  pageTitle('Error');

  const [redirect, setRedirect] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (secondsRemaining === 0) {
      setRedirect(true);
    }
  }, [secondsRemaining]);

  if (redirect) {
    window.location.href = '/';
    return null;
  }

  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg cs-error_page"
      style={{
        backgroundImage: 'url("/images/404-bg.svg")',
      }}
    >
      <Div className="container">
        <img
          style={{
            marginLeft: 'auto',
          }}
          src="/images/funny-404.svg"
          width={ 300 }
          height={ 100 }
          alt="Error 404"
        />
        <SectionHeading
          title=" ¡Ups! Parece que te has perdido.<br/>La página que estás buscando <br/> no
          existe o ha sido movida."
          subtitle={ `Seras redireccionado en ${secondsRemaining} segundos.` }
          btnText="Volver al inicio"
          btnLink="/"
          variant="cs-style1 text-center"
        />
      </Div>
    </Div>
  );
}
