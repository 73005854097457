import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import { sendEmail } from '../../helper/emails';
import useForm from '../../hooks/useForm';

export default function ContactPage() {
  const {
    formState,
    onInputChange,
    resetForm,
    error,
    setError,
  } = useForm({
    name: '',
    email: '',
    projectType: '',
    phone: '',
    message: '',
  });

  const isFormEmpty = Object.values(formState).some((value) => value === '');
  const [emailStatus, setEmailStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailStatus(null);
    if (isFormEmpty) {
      setError(true);
      return;
    }
    setError(false);
    setLoading(true);
    try {
      await sendEmail(formState);
      setEmailStatus('success');
      resetForm();
    } catch (err) {
      setEmailStatus('error');
    }
    setLoading(false);
  };

  pageTitle('Contacto');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Contacto"
        bgSrc="/images/background-home.png"
        pageLinkText=""
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6 contac-page">
            <SectionHeading
              title="¿Tienes un proyecto en mente?"
              subtitle="Comunicate con nosotros"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row" onSubmit={ handleSubmit }>
              <Div className="col-sm-6">
                <label className="cs-primary_color">
                  Nombre / Razón social*
                </label>
                <input
                  type="text"
                  className="cs-form_field"
                  name="name"
                  placeholder="Empresa S.A."
                  value={ formState.name }
                  onChange={ onInputChange }
                  maxLength={ 30 }
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input
                  type="email"
                  className="cs-form_field"
                  name="email"
                  placeholder="ejemplo@email.com"
                  value={ formState.email }
                  onChange={ onInputChange }
                  maxLength={ 40 }
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Tipo de proyecto*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  name="projectType"
                  placeholder="Ej: Landing Page..."
                  value={ formState.projectType }
                  onChange={ onInputChange }
                  maxLength={ 40 }
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Teléfono*</label>
                <input
                  type="text"
                  className="cs-form_field"
                  name="phone"
                  placeholder="Ingrese su teléfono"
                  value={ formState.phone }
                  onChange={ onInputChange }
                  maxLength={ 15 }
                  pattern="[0-9]{1,15}"
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Mensaje*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  name="message"
                  placeholder="Ingrese su consulta"
                  value={ formState.message }
                  onChange={ onInputChange }
                  maxLength={ 200 }
                />
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1" disabled={ loading } type="submit">
                  <span>Enviar mensaje</span>
                  <Icon icon={ loading ? 'line-md:loading-twotone-loop' : 'bi:arrow-right' } />
                </button>
                <Spacing lg="30" md="15" />
                {emailStatus === 'success' && (
                  <div className="col-lg-12 cs-success_color">
                    <Icon icon="material-symbols:check" />
                    {' '}
                    El correo electrónico ha sido enviado con éxito.
                  </div>
                )}
                {emailStatus === 'error' && (
                  <div className="col-lg-12 cs-error_color">
                    <Icon icon="ph:x" />
                    {' '}
                    Ocurrió un error al enviar el correo electrónico. Por favor, inténtalo de nuevo más tarde.
                  </div>
                )}
                {error && (
                  <div className="col-lg-12 cs-error_color">
                    <Icon icon="material-symbols:warning" />
                    {' '}
                    El formulario debe estar completo para enviarse.
                  </div>
                )}
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3561.6843572847356!2d-65.21379712409103!3d-26.786329687935925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94225dc6e5959215%3A0x40bbfecd7d16a2c3!2sTensolite%20SA!5e0!3m2!1ses!2sar!4v1692818611908!5m2!1ses!2sar"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
