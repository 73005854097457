import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Card from '../Card';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import PricingTableList from '../PricingTable/PricingTableList';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Spacing from '../Spacing';

export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Servicios"
        bgSrc="images/mkt-portfolio.png"
      />
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4" />
        <Div className="cs-shape_4" />
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="¿En que podemos ayudarte?"
                subtitle=""
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile" />
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Diseño"
                    link="/service/diseno-grafico"
                    src="/images/Branding.png"
                    alt="branding"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile" />
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Desarrollo web"
                    link="/service/desarrollo"
                    src="/images/developer.png"
                    alt="developer"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Marketing"
                    link="/service/marketing"
                    src="/images/marketing.png"
                    alt="marketing"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile" />
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Publicidad Online"
                    link="/service/diseno-grafico"
                    src="/images/uxui.png"
                    alt="ui ux design"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <SectionHeading
          title="Plan para redes sociales"
          subtitle=""
        />
        <Spacing lg="85" md="40" />
        <PricingTableList />
      </Div>
      <Spacing lg="125" md="55" />
      <TestimonialSlider />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Impulsá tu éxito digital con nosotros: donde la innovación cobra vida y el diseño vende."
          btnText="Contactanos!"
          btnLink="/contact"
          bgSrc="/images/contacto-baner-home.png"
        />
        <Spacing lg="105" md="70" />
      </Div>

    </>
  );
}
