import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';

export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: '/images/francisco-gattoni.png',
      testimonialText:
        '¡Somos una empresa joven en todo el rubro de la construcción, venimos creciendo exponencialmente y muy bien. Vimos y atendimos la necesidad de tener presencia en redes sociales, sitio web y demás. Y conociendo la calidad de Tensolite, que tiene el área de SITENSO, nos pusieron en contacto.',
      avatarName: 'Francisco Gattoni',
      avatarDesignation: 'CEO Con Código SRL',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/luciano-tirabasso.png',
      testimonialText:
        'Como experiencia fue muy buena, nosotros no teníamos experiencia en esto, hicimos consultas a otras empresas, no encontramos buena respuesta, todo el tiempo se nos habló de manera técnica. Desde SITENSO tuvimos el soporte desde el primer momento, nos ayudaron a tomar la decisión y fue acompañado el armado de la página!',
      avatarName: 'Luciano Tirabasso',
      avatarDesignation: 'CEO Con Código SRL',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/Mario.png',
      testimonialText:
        'Uno de los problemas más grandes que teníamos era el control de stock, fundamentalmente del stock terminado y aceitado del mismo. SITENSO se avocó el desafío desarrollando Panel de autogestión y Smart Warehouse. Si tengo que evaluar el impacto que tuvo este desarrollo de SITENSO fue una herramienta que nos ordenó, nos ayudó considerablemente de una manera invalorable para poder hacer el trabajo en forma ordenada, prolija y precisa.',
      avatarName: 'Mario Maccio',
      avatarDesignation: 'Director industrial - Tensolite',
      ratings: '4.5',
    },
  ];
  function SlickArrowLeft({ currentSlide, ...props }) {
    return (
      <div
        { ...props }
        className={
          `slick-prev slick-arrow${currentSlide === 0 ? ' slick-disabled' : ''}`
        }
        aria-hidden="true"
        aria-disabled={ currentSlide === 0 }
      >
        <Icon icon="bi:arrow-left" />
      </div>
    );
  }
  function SlickArrowRight({ currentSlide, slideCount, ...props }) {
    return (
      <div
        { ...props }
        className={
          `slick-next slick-arrow${
            currentSlide === slideCount - 1 ? ' slick-disabled' : ''}`
        }
        aria-hidden="true"
        aria-disabled={ currentSlide === slideCount - 1 }
      >
        <Icon icon="bi:arrow-right" />
      </div>
    );
  }
  return (
    <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
      <Spacing lg="130" md="80" />
      <Div className="cs-shape_3 cs-to_up">
        <img src="/images/shape_1.png" alt="Shape" />
      </Div>
      <Div className="container">
        <Div className="cs-testimonial_slider">
          <Div className="cs-testimonial_slider_left">
            <Slider
              asNavFor={ nav1 }
              ref={ (slider2) => setNav2(slider2) }
              slidesToShow={ 3 }
              swipeToSlide
              focusOnSelect
              centerMode
              centerPadding="0px"
              arrows={ false }
            >
              {testimonialData.map((item, index) => (
                <Div className="slider-nav_item" key={ index }>
                  <Div className="cs-rotate_img">
                    <Div className="cs-rotate_img_in">
                      <img src={ item.testimonialThumb } alt="Thumb" />
                    </Div>
                  </Div>
                </Div>
              ))}
            </Slider>
          </Div>
          <Div className="cs-testimonial_slider_right">
            <Slider
              asNavFor={ nav2 }
              ref={ (slider1) => setNav1(slider1) }
              prevArrow={ <SlickArrowLeft /> }
              nextArrow={ <SlickArrowRight /> }
              className="cs-arrow_style1"
            >
              {testimonialData.map((item, index) => (
                <Div key={ index }>
                  <Testimonial
                    testimonialText={ item.testimonialText }
                    avatarName={ item.avatarName }
                    avatarDesignation={ item.avatarDesignation }
                    ratings={ item.ratings }
                  />
                </Div>
              ))}
            </Slider>
          </Div>
        </Div>
      </Div>
      <Spacing lg="130" md="80" />
    </Div>
  );
}
