import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import Hero4 from '../Hero/Hero4';
import { pageTitle } from '../../helper';
import Div from '../Div';
import FunFact2 from '../FunFact/FunFact2';
import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';
import MovingText2 from '../MovingText/MovingText2';
import VideoModal from '../VideoModal';
import Portfolio from '../Portfolio';
import TimelineSlider from '../Slider/TimelineSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Cta from '../Cta';
import LogoList from '../LogoList';
import PostList from '../Post/PostList';

export default function DigitalAgencyHome() {
  const [itemShow, setItemShow] = useState(6);
  pageTitle('Digital Agency');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroSocialLinks = [
    {
      name: 'Behance',
      links: '/',
    },
    {
      name: 'Twitter',
      links: '/',
    },
  ];
  const funfaceData = [
    {
      id: 0,
      title: 'Digital products',
      factNumber: '550',
    },
    {
      id: 1,
      title: 'Global happy clients',
      factNumber: '40K',
    },
    {
      id: 2,
      title: 'Project completed',
      factNumber: '50k',
    },
    {
      id: 3,
      title: 'Team members',
      factNumber: '250',
    },
  ];
  const serviceData1 = [
    {
      title: 'UI/UX Design',
      href: '/service/service-details',
    },
    {
      title: 'Marketing',
      href: '/service/service-details',
    },
    {
      title: 'Diseño',
      href: '/service/service-details',
    },
  ];
  const serviceData2 = [
    {
      title: 'SEO',
      href: '/service/service-details',
    },
    {
      title: 'App design',
      href: '/service/service-details',
    },
    {
      title: 'React Developer',
      href: '/service/service-details',
    },
  ];
  const portfolioData = [
    {
      id: 0,
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_11.jpeg',
      category: 'ui_ux_design',
    },
    {
      id: 1,
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_12.jpeg',
      category: 'logo_design',
    },
    {
      id: 2,
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_13.jpeg',
      category: 'web_design',
    },
    {
      id: 3,
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_14.jpeg',
      category: 'mobile_apps',
    },
    {
      id: 4,
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_15.jpeg',
      category: 'ui_ux_design',
    },
    {
      id: 5,
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_16.jpeg',
      category: 'web_design',
    },
    {
      id: 6,
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_11.jpeg',
      category: 'ui_ux_design',
    },
    {
      id: 7,
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_12.jpeg',
      category: 'logo_design',
    },
    {
      id: 8,
      title: 'Colorful Art Work',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/portfolio_13.jpeg',
      category: 'web_design',
    },
  ];
  return (
    <>
      <Hero4
        title="We Are <span>A</span>rino <br />Digital Agency"
        subtitle="We deliver best problem solving solution for our client and provide finest finishing product in present and upcoming future."
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={ heroSocialLinks }
        phoneNumber="+044 546664"
        email="info@arino.com"
      />

      <Div className="container">
        <FunFact2
          data={ funfaceData }
          variant="cs-type1"
          bgUrl="/images/funfact_shape_bg.svg"
        />
      </Div>

      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="What we provide"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="65" md="45" />
      </Div>

      <MovingText2 data={ serviceData1 } />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={ serviceData2 } />

      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4" />
        <Div className="cs-shape_4" />
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="Uk’s best digital agency ever"
                subtitle="Why Choose Us"
                btnText="Learn More"
                btnLink="/about"
              />
              <Spacing lg="45" md="45" />
            </Div>
            <Div className="col-lg-7 offset-xl-1">
              <Div className="cs-half_screen">
                <VideoModal
                  videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
                  bgUrl="/images/video-portada.png"
                  variant="cs-style1 cs-size1"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Portfolio to explore"
          subtitle="Latest Projects"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={ `${
                index === 0 || index === 3 || index === 4
                  ? 'col-lg-8'
                  : 'col-lg-4'
              }` }
              key={ item?.id }
            >
              <Portfolio
                title={ item.title }
                subtitle={ item.subtitle }
                href={ item.href }
                src={ item.src }
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={ () => setItemShow(itemShow + 3) }
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>

      <Spacing lg="140" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-4">
            <SectionHeading
              title="We get multiple awards"
              subtitle="Our Awards"
              variant="cs-style1"
            />
            <Spacing lg="90" md="45" />
          </Div>
          <Div className="col-xl-7 offset-xl-1">
            <TimelineSlider />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />

      <TestimonialSlider />

      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4" />
        <Div className="cs-shape_4" />
        <Spacing lg="145" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="45" md="45" />
            </Div>
            <Div className="col-lg-7 offset-xl-1">
              <PostList />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />

      <Div className="container">
        <SectionHeading
          title="Our reputed partner"
          subtitle="Top Clients"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />

      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
